const Danila = () => {
  return (
    <main className="main effect-fade-in effect-zoom-in">

    <div className="main__wrapper">
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
      <h1 className="main__title">Пойдём покурим, заебал!</h1>
    </div>
  </main>
  );
};

export default Danila;
