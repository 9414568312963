import { useEffect } from 'react';
import './index.css';

const Karina = () => {
  return (
    <>
      <div className='gift'>
        <div className="background">
          <div className="card">
            <div className="first"></div>
            <div className="second"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Karina;
