import './green.css';

export const BranchLeft = ({props}: any) => {
    const { temp, wind } = props;

    return (
        <svg
        className={`branch branchLeft ${wind} ${temp}`}
            xmlns="http://www.w3.org/2000/svg"
            width={653}
            height={728}

        >
            <g className={'leaf1' + wind}>
                <path
                    d="M258.375 284.774c-77.165-34.154-127.182-5.022-150.335 15.66 70.481 10.847 134.441 43.286 194.667 88.106a6.833 6.833 0 0 1 1.228 9.434 6.835 6.835 0 0 1-9.39 1.528c-61.116-45.484-126.154-77.995-198.317-86.899-2.115 2.553-3.172 4.11-3.172 4.11-.09 2.321-.138 4.616-.143 6.882-.223 98.284 79.355 144.997 184.062 137.01 107.118-8.171 113.04 10.801 111.643 4.469-7.311-33.066-37.507-139.259-130.243-180.3Z"
                />
            </g>

            <g className={'leaf2' + wind}>
                <path
                    d="M10.028 323.049c28.705-3.382 56.279-3.02 82.885.546.005-2.266.053-4.561.143-6.882 0 0 1.057-1.557 3.172-4.11 72.163 8.904 137.201 41.415 198.317 86.899a6.845 6.845 0 0 0 2.94 1.157 6.852 6.852 0 0 0-1.493-1.568C210.366 335.367 117.176 296.668 8.43 309.476a6.835 6.835 0 0 0-3.435 12.151 6.83 6.83 0 0 0 5.033 1.422ZM268.923 481.447c-32.885-52.301-75.171-55.071-97.689-51.984 38.359 35.416 63.927 81.275 82.163 133.125a5.005 5.005 0 0 1-9.44 3.318c-18.505-52.617-44.698-98.961-84.874-133.885-2.328.684-3.607 1.198-3.607 1.198a137.318 137.318 0 0 0-2.902 4.12c-40.337 59.587-11.125 120.502 55.721 158.482 68.385 38.854 64.221 52.796 65.962 48.38 9.086-23.068 34.189-99.9-5.334-162.754Z"

                /></g>

            <g className={'leaf3' + wind}>
                <path
                    d="M102.469 403.104c18.813 9.688 35.409 21.187 50.105 34.235.93-1.374 1.898-2.748 2.902-4.12 0 0 1.279-.514 3.607-1.198 40.176 34.924 66.369 81.268 84.874 133.885a5.003 5.003 0 0 0 1.312 1.905 5.023 5.023 0 0 0-.265-1.563c-25.927-73.717-66.683-135.335-137.953-172.039a4.993 4.993 0 0 0-3.816-.317 4.997 4.997 0 0 0-3.46 4.35 5.005 5.005 0 0 0 2.694 4.862ZM557.923 385.404c-32.885-52.302-75.171-55.071-97.689-51.984 38.359 35.416 63.927 81.275 82.163 133.125a5.002 5.002 0 0 1-3.113 6.23 4.999 4.999 0 0 1-5.015-1.007 5.01 5.01 0 0 1-1.312-1.905c-18.505-52.618-44.698-98.961-84.874-133.886-2.328.685-3.607 1.199-3.607 1.199a137.318 137.318 0 0 0-2.902 4.12c-40.337 59.587-11.125 120.502 55.721 158.482 68.385 38.854 64.221 52.796 65.962 48.38 9.086-23.068 34.189-99.901-5.334-162.754Z"

                /></g>

            <g className={'leaf4' + wind}>
                <path
                    d="M391.469 307.061c18.813 9.688 35.409 21.186 50.105 34.235.93-1.374 1.898-2.748 2.902-4.12 0 0 1.279-.514 3.607-1.199 40.176 34.925 66.369 81.268 84.874 133.886a5.01 5.01 0 0 0 1.312 1.905 5.023 5.023 0 0 0-.265-1.563c-25.927-73.718-66.683-135.335-137.953-172.039a4.993 4.993 0 0 0-3.816-.317 4.997 4.997 0 0 0-2.923 2.474 5.019 5.019 0 0 0-.317 3.816 5.01 5.01 0 0 0 2.474 2.922ZM547.244 137.178c-44.723 2.136-61.528 27.842-67.597 43.155 36.014-11.604 74.065-11.414 113.286-4.464a3.627 3.627 0 0 1-1.266 7.14c-39.802-7.054-78.382-7.021-114.731 5.904-.397 1.714-.528 2.703-.528 2.703a97.817 97.817 0 0 0 1.567 3.299c23.251 46.678 72.103 49.928 119.877 21.256 48.874-29.332 56.192-21.739 54.024-24.411-11.325-13.949-50.886-57.15-104.632-54.582Z"

                /></g>

            <g className={'leaf5' + wind}>
                <path
                    d="M438.526 214.353c12.813-8.426 25.98-14.807 39.449-19.438a97.817 97.817 0 0 1-1.567-3.299s.131-.989.528-2.703c36.349-12.925 74.929-12.958 114.731-5.904a3.623 3.623 0 0 0 1.669-.15 3.64 3.64 0 0 0-1.081-.389c-55.763-9.882-109.169-6.095-157.713 25.824a3.637 3.637 0 0 0-1.559 2.296 3.623 3.623 0 0 0 1.514 3.733 3.623 3.623 0 0 0 4.029.03ZM203.243 41.178c-44.722 2.136-61.527 27.842-67.596 43.155 36.014-11.603 74.065-11.414 113.286-4.464a3.626 3.626 0 0 1 .403 6.99 3.625 3.625 0 0 1-1.669.15c-39.802-7.054-78.382-7.02-114.732 5.904-.396 1.714-.527 2.703-.527 2.703a98.375 98.375 0 0 0 1.567 3.3c23.251 46.677 72.103 49.927 119.877 21.255 48.874-29.332 56.192-21.74 54.024-24.41-11.325-13.95-50.886-57.151-104.633-54.583Z"

                /></g>

            <g className={'leaf1' + wind}>
                <path
                    d="M94.525 118.353c12.814-8.426 25.981-14.807 39.45-19.438a98.375 98.375 0 0 1-1.567-3.299s.131-.99.527-2.703c36.35-12.925 74.93-12.958 114.732-5.904a3.625 3.625 0 0 0 1.669-.15 3.623 3.623 0 0 0-1.081-.389c-55.763-9.882-109.169-6.094-157.713 25.824a3.63 3.63 0 0 0-1.57 3.711 3.62 3.62 0 0 0 1.525 2.318 3.622 3.622 0 0 0 4.028.03ZM488.772 609.856c-27.812-35.089-58.494-33.752-74.474-29.754 30.426 22.492 52.459 53.516 69.681 89.433a3.625 3.625 0 0 1-1.751 4.734 3.632 3.632 0 0 1-4.788-1.6c-17.477-36.449-39.998-67.772-71.694-89.767-1.623.676-2.504 1.147-2.504 1.147a101.12 101.12 0 0 0-1.766 3.197c-24.365 46.106 1.48 87.689 52.629 109.784 52.327 22.604 50.426 32.976 51.332 29.657 4.729-17.335 16.76-74.664-16.665-116.831Z"

                /></g>

            <g className={'leaf2' + wind}>
                <path
                    d="M362.683 566.53c14.316 5.497 27.178 12.473 38.793 20.716a101.12 101.12 0 0 1 1.766-3.197s.881-.471 2.504-1.147c31.696 21.995 54.217 53.318 71.694 89.767.263.507.64.94 1.095 1.269a3.625 3.625 0 0 0-.314-1.105c-24.486-51.065-58.702-92.245-112.939-113.072a3.625 3.625 0 1 0-2.599 6.769ZM358.999 173.33c-83.869-9.325-122.787 33.52-138.636 60.215 70.475-10.887 141.236.78 212.165 25.378a6.839 6.839 0 0 1 .633 12.488 6.842 6.842 0 0 1-5.114.424c-71.978-24.963-143.788-36.374-215.282-23.128-1.248 3.071-1.786 4.874-1.786 4.874a185.247 185.247 0 0 0 1.936 6.606c29.391 93.786 119.344 114.36 216.783 75.205 99.682-40.056 111.043-23.75 107.803-29.367-16.93-29.327-77.71-121.494-178.502-132.695Z"

                /></g>

            <g className={'leaf3' + wind}>
                <path
                    d="M133.715 284.632c26.353-11.87 52.755-19.832 79.2-24.445a185.247 185.247 0 0 1-1.936-6.606s.538-1.803 1.786-4.874c71.494-13.246 143.304-1.835 215.282 23.128 1.028.32 2.108.39 3.152.217a6.877 6.877 0 0 0-1.897-1.045c-100.842-34.973-201.362-43.805-301.199 1.164a6.843 6.843 0 0 0-3.584 3.81 6.835 6.835 0 0 0 3.969 8.811 6.836 6.836 0 0 0 5.227-.16Z"

                />
            </g>

        </svg>
    );
};

export const BranchRight = ({props}: any) => {
    const { temp, wind } = props;

    return (
        <svg
            className={`branch branchRight ${wind} ${temp}`}
            xmlns="http://www.w3.org/2000/svg"
            width={752}
            height={761}
        >

            <g className={'leaf1' + wind}>
                <path
                    d="M311.678 298.977c68.221-49.666 123.259-31.744 150.254-16.414-66.605 25.475-122.28 70.679-171.692 127.198a6.839 6.839 0 0 0 10.291 8.994c50.142-57.357 106.856-102.858 175.516-126.789 2.606 2.049 3.967 3.348 3.967 3.348.578 2.25 1.109 4.482 1.592 6.697 20.957 96.023-46.973 158.476-151.008 172.764-106.43 14.615-108.216 34.409-108.185 27.925.168-33.863 7.277-144.037 89.265-203.723Z"
                />
            </g>

            <g className={'leaf2' + wind}>
                <path
                    d="M562.51 283.988c-28.772 2.751-55.649 8.923-80.904 18.023a187.306 187.306 0 0 0-1.592-6.697s-1.361-1.299-3.967-3.348c-68.66 23.931-125.374 69.432-175.516 126.789a6.836 6.836 0 0 1-2.63 1.751 6.863 6.863 0 0 1 1.129-1.848c70.251-80.356 153.178-137.848 262.178-148.275a6.842 6.842 0 0 1 5.001 1.532 6.853 6.853 0 0 1 2.452 4.62 6.838 6.838 0 0 1-6.151 7.453ZM518.738 370.447c32.885-52.301 75.171-55.071 97.689-51.984-38.359 35.416-63.927 81.275-82.163 133.125a5.005 5.005 0 0 0 9.44 3.318c18.505-52.617 44.698-98.961 84.874-133.885 2.328.684 3.607 1.198 3.607 1.198a137.318 137.318 0 0 1 2.902 4.12c40.337 59.587 11.125 120.502-55.721 158.482-68.385 38.854-64.221 52.796-65.962 48.38-9.086-23.068-34.189-99.9 5.334-162.754Z"
                /></g>

            <g className={'leaf3' + wind}>
                <path
                    className='leaf3'
                    d="M685.192 292.104c-18.813 9.688-35.409 21.187-50.105 34.235a137.318 137.318 0 0 0-2.902-4.12s-1.279-.514-3.607-1.198c-40.176 34.924-66.369 81.268-84.874 133.885a5.003 5.003 0 0 1-1.312 1.905 5.023 5.023 0 0 1 .265-1.563c25.927-73.717 66.683-135.335 137.953-172.039a4.993 4.993 0 0 1 3.816-.317 4.997 4.997 0 0 1 2.923 2.474 5.019 5.019 0 0 1 .317 3.816 5 5 0 0 1-2.474 2.922ZM281.316 505.385c32.885-52.302 75.171-55.071 97.689-51.984-38.359 35.416-63.927 81.275-82.163 133.125a5.002 5.002 0 0 0 3.113 6.231 5.004 5.004 0 0 0 6.326-2.913c18.506-52.618 44.699-98.961 84.875-133.886 2.328.685 3.607 1.199 3.607 1.199a139.164 139.164 0 0 1 2.902 4.12c40.337 59.587 11.124 120.502-55.721 158.482-68.385 38.854-64.221 52.796-65.962 48.38-9.086-23.068-34.189-99.901 5.334-162.754Z"
                /></g>

            <g className={'leaf4' + wind}>
                <path
                    d="M447.769 427.042c-18.812 9.688-35.408 21.186-50.104 34.235a139.164 139.164 0 0 0-2.902-4.12s-1.279-.514-3.607-1.199c-40.176 34.925-66.369 81.268-84.875 133.886a5.008 5.008 0 0 1-1.311 1.905 5.023 5.023 0 0 1 .265-1.563c25.927-73.718 66.683-135.335 137.953-172.039a4.994 4.994 0 0 1 3.816-.317 4.997 4.997 0 0 1 3.46 4.35 4.99 4.99 0 0 1-2.695 4.862ZM420.961 554.152c60.161-55.043 114.944-43.762 142.519-31.949-61.216 31.639-109.81 81.045-151.172 140.643a6.608 6.608 0 0 0 8.515 9.492 6.61 6.61 0 0 0 2.336-1.965c41.973-60.48 91.533-110.284 154.888-140.661 2.723 1.687 4.17 2.788 4.17 2.788a181.51 181.51 0 0 1 2.251 6.26c30.468 89.952-28.036 157.242-126.401 182.169-100.628 25.501-100.211 44.7-100.88 38.471-3.486-32.537-8.529-139.101 63.774-205.248Z"

                /></g>

            <g className={'leaf5' + wind}>
                <path
                    d="M660.216 512.737c-27.333 5.742-52.477 14.563-75.748 26.023a181.51 181.51 0 0 0-2.251-6.26s-1.447-1.101-4.17-2.788c-63.355 30.377-112.915 80.181-154.888 140.661a6.61 6.61 0 0 1-2.336 1.965c.191-.669.487-1.31.885-1.896 58.805-84.733 132.245-148.875 235.793-170.629a6.608 6.608 0 0 1 6.808 2.735 6.606 6.606 0 0 1-4.093 10.189ZM237.7 232.072c44.773.2 62.673 25.156 69.398 40.192-36.481-10.035-74.488-8.201-113.373.438a3.625 3.625 0 0 0-2.641 4.301 3.628 3.628 0 0 0 4.216 2.777c39.459-8.767 78.004-10.402 114.879.94.47 1.694.644 2.678.644 2.678a98.426 98.426 0 0 1-1.424 3.363c-21.211 47.64-69.877 52.998-118.846 26.418-50.096-27.192-57.079-19.29-55.029-22.053 10.712-14.425 48.369-59.297 102.176-59.054Z"

                /></g>

            <g className={'leaf1' + wind}>
                <path
                    d="M349.652 304.476c-13.165-7.864-26.596-13.671-40.253-17.715.49-1.098.964-2.22 1.424-3.363 0 0-.174-.984-.644-2.678-36.875-11.342-75.42-9.707-114.879-.94a3.63 3.63 0 0 1-1.675-.077 3.615 3.615 0 0 1 1.063-.435c55.284-12.284 108.804-10.808 158.683 18.982a3.627 3.627 0 0 1-3.719 6.226ZM67.935 622.462c40.702-18.656 67.44-3.547 79.867 7.264-37.317 6.244-71.026 23.899-102.667 48.096a3.624 3.624 0 0 0-.586 5.013 3.632 3.632 0 0 0 3.44 1.38 3.639 3.639 0 0 0 1.552-.634c32.11-24.556 66.389-42.255 104.613-47.48 1.14 1.339 1.711 2.158 1.711 2.158.064 1.231.105 2.448.124 3.651.8 52.141-41.095 77.477-96.702 73.966C2.4 712.285-.61 722.391.087 719.022c3.65-17.593 18.932-74.143 67.848-96.56Z"

                /></g>

            <g className={'leaf2' + wind}>
                <path
                    d="M199.959 641.045c-15.253-1.595-29.879-1.212-43.97.865a101.05 101.05 0 0 0-.124-3.651s-.571-.819-1.711-2.158c-38.224 5.225-72.504 22.924-104.613 47.48a3.639 3.639 0 0 1-1.551.634c.212-.318.475-.603.781-.842 44.986-34.403 94.158-55.581 151.941-49.54a3.618 3.618 0 0 1 3.099 2.574 3.623 3.623 0 0 1-3.852 4.638ZM122.811 472.224c31.566-31.753 61.906-26.989 77.337-21.226-32.753 18.943-58.122 47.304-79.259 81.066a3.627 3.627 0 0 0 6.147 3.847c21.449-34.262 47.338-62.866 81.297-81.173 1.537.855 2.359 1.421 2.359 1.421.492 1.13.958 2.255 1.397 3.375 19.048 48.544-11.291 86.971-64.593 103.198-54.53 16.601-53.802 27.121-54.33 23.721-2.758-17.755-8.292-76.071 29.645-114.229Z"

                /></g>

            <g className={'leaf3' + wind}>
                <path
                    d="M252.959 443.293c-14.842 3.859-28.404 9.351-40.87 16.241a98.517 98.517 0 0 0-1.397-3.375s-.822-.566-2.359-1.421c-33.959 18.307-59.848 46.911-81.297 81.173a3.629 3.629 0 0 1-1.23 1.138c.087-.372.233-.731.436-1.063 30.051-48.001 68.664-85.089 124.892-99.711a3.615 3.615 0 0 1 2.749.383 3.624 3.624 0 0 1-.924 6.635ZM428.662 62.33c83.869-9.325 122.787 33.52 138.636 60.215-70.475-10.887-141.236.78-212.165 25.378a6.839 6.839 0 0 0-.633 12.488 6.842 6.842 0 0 0 5.114.424c71.978-24.963 143.788-36.374 215.282-23.128 1.248 3.071 1.786 4.874 1.786 4.874a185.247 185.247 0 0 1-1.936 6.606c-29.391 93.786-119.344 114.36-216.783 75.205-99.682-40.056-111.043-23.75-107.803-29.367 16.93-29.327 77.71-121.494 178.502-132.695Z"

                /></g>

            <g className={'leaf4' + wind}>
                <path
                    d="M653.946 173.632c-26.353-11.87-52.755-19.832-79.2-24.445a185.247 185.247 0 0 0 1.936-6.606s-.538-1.803-1.786-4.874c-71.494-13.246-143.304-1.835-215.282 23.128a6.842 6.842 0 0 1-3.152.217 6.886 6.886 0 0 1 1.896-1.045c100.843-34.973 201.363-43.805 301.2 1.164a6.832 6.832 0 0 1 1.876 11.208 6.827 6.827 0 0 1-7.488 1.253Z"

                /></g>
        </svg>);
};
